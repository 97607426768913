.loading {
  text-align: center;
  width: 100%;
}

.login-container .control-group > label {
  display: none;
}

.clear {
  clear: both;
}

.page .title {
  display: none;
}

.page-content-wrap h5 {
  padding-left: 15px;
  font-weight: bold;
}

.page-content-wrap .profile {
  background: none;
}

#root {
  height: 100%;
}

.profile .form-group label {
  padding-top: 10px;
}

.profile .btn-primary {
  margin-top: 10px;
}

form .errors li {
  color: red;
}

.task-form label,
.project-form label {
  width: 100%;
}

.task-form label,
.project-form .DateInput {
  width: 100%;
}

.task-form .SingleDatePicker input,
.project-form .SingleDatePicker input {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #c0c0c0;
}

.form-group-separated .form-group [class^="col-md-"] {
  border-left: 1px dashed #d5d5d5;
  padding: 5px 10px;
}

.btn.btn-rounded.btn-primary:hover,
.btn.btn-rounded.btn-primary:focus {
  background-color: #c0c0c0;
}

#root > .alert {
  margin-bottom: 0;
}

#filter {
  z-index: 99;
}

#filter label {
  display: none;
}

#filter .checkbox label {
  display: block;
}

.ReactTable button,
.ratio {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  color: black;
  padding: 7px 5px;
}

.ReactTable .rt-td {
  padding: 0 !important;
  text-align: center;
}

.ReactTable .rt-tr-group {
  border: 0 !important;
}

/* Tooltip container */
.mytooltip {
  display: inline-block;
}

/* Tooltip text */
.mytooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
}

.mytooltip .tooltiptext {
  top: 30px;
  right: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.mytooltip:hover .tooltiptext {
  visibility: visible;
}

.panel {
  min-height: 100px;
  padding-top: 10px;
}

.roles span {
  display: block;
}

.post-time,
.post-author {
  font-size: 14px;
  color: #99a0aa;
  line-height: 20px;
}

.posts .post-item .post-date {
  font-size: 13px;
}

table tr.inactive td {
  background-color: #ececec !important;
  color: #b9b9b9;
}

table tr.inactive td > a {
  color: #b9b9b9;
}

.clearfix {
  clear: both;
}

.profile-data a,
.profile-data a:hover {
  color: white;
  text-decoration: none;
}

.modal-content .control-group > label {
  display: none;
}

.error-occurred {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 10000;
  padding: 0 20px;
}

.react-confirm-alert .info {
  margin-top: 10px;
  font-style: italic;
}

.configuration-check {
  margin-top: 10px;
}

.configuration-check .alert a {
  color: #fff;
  text-decoration: underline;
}

.not-found-information {
  font-size: 20px;
}

.not-found-information .slug {
  color: red;
}

.x-navigation-reduced .page-sidebar {
  display: none;
}

.x-navigation-reduced .page-content {
  margin-left: 0;
}

.x-navigation.x-navigation-horizontal .xn-icon-button > button {
  padding: 15px 10px;
  text-align: center;
  width: 50px;
  background: #33414e;
  border: #33414e;
}

.x-navigation.x-navigation-horizontal .xn-icon-button > button > span {
  color: #fff;
}

.update-version {
  text-align: center;
  font-weight: bold;
  border-radius: 0;
}

.update-version button {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}

li.version {
  height: 100%;
  line-height: 50px;
}

.form-check label {
  margin-left: 10px;
}
